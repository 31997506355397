import type { EditorialEntity, GetEditorialsEqualsCategoryIdQuery } from '@twlvxtwlv/cms'

export function mapEditorialsByPage(
  editorials: GetEditorialsEqualsCategoryIdQuery[] | null | undefined
): EditorialEntity[] | null {
  if (!editorials) {
    return null
  }
  const editorialEntityPage = editorials.filter(Boolean).map((page) => page.editorials?.data)
  const editorialEntities = editorialEntityPage.filter(Boolean).flatMap((p) => p) ?? null
  return editorialEntities as EditorialEntity[] | null
}
